// Clarity
$(document).ready(function(){

  $(".site-nav__dropdown-link:contains('Space')").css("color", "white");
  $('.m09').parent('div').addClass('m09-parent');
  $(".site-nav__dropdown-link:contains('Space')").addClass("space-nav");
  $(".mobile-nav__link:contains('Space')").addClass("space-nav");

  $('.m04-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    
    
    infinite: false,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false
        }
      }

    ]
  });
  
  $('.sample-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: false,
    arrows: true,
    dots: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false
        }
      }

    ]
  });


  $('.m02-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
    autoplay: false,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

    ]
  });

  $('.m02-home-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: true,
    dots: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

    ]
  });


  $(".feature-ingredient-image").hover(function(){
    $( this ).addClass( "hover" );
    $('.feature-ingredient[data-ingredient="'+$( this ).attr("data-ingredient")+'"]').addClass('hover');
  }, function(){
    $( this ).removeClass( "hover" );
    $('.feature-ingredient[data-ingredient="'+$( this ).attr("data-ingredient")+'"]').removeClass('hover');
  });

  $(".feature-ingredient").hover(function(){
    $( this ).addClass( "hover" );
    $('.feature-ingredient-image[data-ingredient="'+$( this ).attr("data-ingredient")+'"]').addClass('hover');
  }, function(){
    $( this ).removeClass( "hover" );
    $('.feature-ingredient-image[data-ingredient="'+$( this ).attr("data-ingredient")+'"]').removeClass('hover');
  });


  if ($(window).width() >= 800){	
    // do something here
    $('.product__thumbs').insertAfter('.product-single__sku');
  }	


  $('#addall').click(function() {
    var productJSON = [];
    $('.product-routine .grid-product').each(function() {
      var product = JSON.parse($(this).find('.product-grid-item-json').first().text().replace(/\n\r\t/g, "").replace(/\s{2,}/g,''));
      productJSON.push({ quantity: 1, id: product.id, properties: product.properties })
    })

    $('#addall').addClass('btn--loading');

    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/cart/add.js', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({
      items: productJSON}))
    xhr.onload = function() {
      $('#addall').removeClass('btn--loading');
      $('body').trigger('added.ajaxProduct', this.$addToCart);
    };
  })

  $('.grid-product__link').on('click', function(e) {
    if($(e.target).hasClass('add-grid-item')) {
      e.preventDefault();
      if($(e.target).attr('disabled')){
        
      } else {
        var anchorTag = this;
        $(this).find('#add-grid-item').first().addClass('btn--loading');
        var product = JSON.parse($(this).find('.product-grid-item-json').text().replace(/\n\r\t/g, "").replace(/\s{2,}/g,''));
        var xhr = new XMLHttpRequest();
        xhr.open("POST", '/cart/add.js', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({
          items: [product]}))
        xhr.onload = function() {
          if($(e.target).text() == 'Pick'){
          	location.reload();
          } else {
          	$(anchorTag).find('#add-grid-item').first().removeClass('btn--loading');
            $('body').trigger('added.ajaxProduct', this.$addToCart);
          }
        };	
      }
     
    } else {

    }
  })

$('body').on('added.ajaxProduct', function(evt, returnFocusEl) {
    function handleResponse() {
  var newHeader = JSON.parse(this.responseText);  
  const headerDiv = document.querySelector('div[data-section-type="header-section"]');
  headerDiv.innerHTML = newHeader['header'];    
}
const request = new XMLHttpRequest();
request.addEventListener('load', handleResponse);
request.open('GET', '/?sections=header', true);
request.send();
        });
  
  $('.add-to-bag').on('click', function(){
    $(this).addClass('btn--loading');
    var anchorTag = this;
    var productJSON = [{ quantity: 1, id: $(this).attr('data-product-variant-id') }];
    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/cart/add.js', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({
      items: productJSON}))
    xhr.onload = function() {
      $(anchorTag).removeClass('btn--loading');
      $('body').trigger('added.ajaxProduct', this.$addToCart);
    };	
  })
  
  $('.promo-add-to-bag').on('click', function(){
  	$(this).addClass('btn--loading');
    var anchorTag = this;
    var productJSON = [{ quantity: 1, id: $(this).attr('data-product-variant-id') }];
    var xhr = new XMLHttpRequest();
    xhr.open("POST", '/cart/add.js', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({
      items: productJSON}))
    xhr.onload = function() {
      $(anchorTag).removeClass('btn--loading');
      $(".promo-cart").load(" .promo-cart > *");
      $(".cart__items").load(" .cart__items > *");
    };	
  })
});

!function(e){e.fn.shorten=function(s){"use strict";var t={showChars:100,minHideChars:10,ellipsesText:"...",moreText:"more",lessText:"less",onLess:function(){},onMore:function(){},errMsg:null,force:!1};return s&&e.extend(t,s),e(this).data("jquery.shorten")&&!t.force?!1:(e(this).data("jquery.shorten",!0),e(document).off("click",".morelink"),e(document).on({click:function(){var s=e(this);return s.hasClass("less")?(s.removeClass("less"),s.html(t.moreText),s.parent().prev().animate({height:"0%"},function(){s.parent().prev().prev().show()}).hide("fast",function(){t.onLess()})):(s.addClass("less"),s.html(t.lessText),s.parent().prev().animate({height:"100%"},function(){s.parent().prev().prev().hide()}).show("fast",function(){t.onMore()})),!1}},".morelink"),this.each(function(){var s=e(this),n=s.html(),r=s.text().length;if(r>t.showChars+t.minHideChars){var o=n.substr(0,t.showChars);if(o.indexOf("<")>=0){for(var a=!1,i="",h=0,l=[],c=null,f=0,u=0;u<=t.showChars;f++)if("<"!=n[f]||a||(a=!0,c=n.substring(f+1,n.indexOf(">",f)),"/"==c[0]?c!="/"+l[0]?t.errMsg="ERROR en HTML: the top of the stack should be the tag that closes":l.shift():"br"!=c.toLowerCase()&&l.unshift(c)),a&&">"==n[f]&&(a=!1),a)i+=n.charAt(f);else if(u++,h<=t.showChars)i+=n.charAt(f),h++;else if(l.length>0){for(j=0;j<l.length;j++)i+="</"+l[j]+">";break}o=e("<div/>").html(i+'<span class="ellip">'+t.ellipsesText+"</span>").html()}else o+=t.ellipsesText;var m='<div class="shortcontent">'+o+'</div><div class="allcontent">'+n+'</div><span><a href="javascript://nop/" class="morelink">'+t.moreText+"</a></span>";s.html(m),s.find(".allcontent").hide(),e(".shortcontent p:last",s).css("margin-bottom",0)}}))}}(jQuery);
$('.ingredients-list').shorten({
  showChars: 300,
  moreText: 'Read more',
  lessText: 'Read less'
});

$('.product-single__description-full').shorten({
  showChars: 300,
  moreText: 'Read more',
  lessText: 'Read less'
});

$('.product-grid-description').shorten({
  showChars: 100,
  moreText: 'Read more',
  lessText: 'Read less'
});


$(".scrollTo").on('click', function(e) {
  e.preventDefault();
  var target = $(this).attr('href');
  $('html, body').animate({
    scrollTop: ($(target).offset().top)
  }, 2000);
});

var all_links = document.querySelectorAll('a');
for (var i = 0; i < all_links.length; i++){
  var a = all_links[i];
  if(a.hostname != location.hostname) {
    a.rel = 'noopener';
    a.target = '_blank';
  }
}

$( '.mobile-nav__link[href$="mobile"]' ).parent('div').addClass('hide');
$( '.mobile-nav__link[href^="/products"]' ).parent('div').addClass('hide');
$( '.site-nav__dropdown-link.site-nav__dropdown-link--top-level[href^="/products"]' ).parent('div').addClass('hide');

$("#CartGiftWrapAgree").on('change', function(){
  if(this.checked) {
    $(".gift-note-area").removeClass('hide');
  } else {
    $(".gift-note-area").addClass('hide')
  }
})

function cartTriggersLoad() {
  $('.cart__product-qty').on('change', function(){
//     console.log(`cart__product-qty = ${$(this).attr("data-variant")}`);
    let variants = {};
    variants[`${parseInt($(this).attr("data-variant"))}`] = parseInt($(this).val());
    let formData = {
      'id': parseInt($(this).attr("data-variant")),
      'quantity': parseInt($(this).val())
    };
    fetch('/cart/update.js', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({updates: variants})
    })
    .then(response => {
      $(".cart__items").load(" .cart__items > *", function(){
         cartTriggersLoad();
      })
      $(".promo-cart").load(" .promo-cart > *");
      $(".cart_total_price").load(" .cart_total_price > *");
    })
    .catch((error) => {
      console.log('Error:' + error);
    });
  });
}

$('body').on('DOMNodeInserted', '.page-content.page-content--product', function(e) {
  if($(e.target).attr('class') === 'spr-container'){
    $('.spr-summary-actions-newreview').on('click', function(){
      if($('.spr-form').css('display') == 'block')
      {
        $('.spr-summary-actions-newreview').addClass('close-btn');
        $( ".close-btn" ).append( "<span class='close-x' style=''>X</span>" );
      } else {
        $('.spr-summary-actions-newreview').removeClass('close-btn');
        $(this).find('.close-x').remove()
      }
    });	
  }
});

$('body').on('DOMNodeInserted', '.page-content.page-content--product', function(e) {
  $('.address-new-toggle').on('click', function(){
    if($('#AddressNewForm').css('display') == 'block')
    {
      $('#AddressNewForm').addClass('close-btn');
      $( ".close-btn" ).append( "<span class='close-x' style=''>X</span>" );
    } else {
      $('#AddressNewForm').removeClass('close-btn');
      $(this).find('.close-x').remove()
    }
  });	
});


function updateClassesForSampleProducts() {
  if($('.sample-checkbox:checkbox:checked').length >= max_sample) {
    $('.sample-checkbox:checkbox:not(":checked")').each(function() {
      $(this).addClass("sample-checkbox-disabled");
      $(this).parents(".grid__item").find("#add-grid-item").prop("disabled", true);
      $(this).attr("disabled", true);
    })
  } else {
    $('.sample-checkbox:checkbox:not(":checked")').each(function() {
      $(this).removeClass("sample-checkbox-disabled");
      $(this).parents(".grid__item").find("#add-grid-item").removeAttr("disabled");
      $(this).removeAttr("disabled");

    })
  }
}

$(document).ready(function () {
  cartTriggersLoad();

$('.sample-slider .grid-product').click(function(e){
  if($(e.target).hasClass('morelink')) {
    e.preventDefault();
  }
  else if($(e.target).hasClass('sample-checkbox')) {
    if($(this).find('input').is(':checked')) {
      if($(this).find('input').attr('checked')) {
        $(this).find('input').removeAttr('checked');
      } else {
        $(this).find('input').attr('checked', 'checked');
      }
    }
    if($(this).find('input').attr('checked')) {
      $(this).find('input').attr('checked', 'checked');
      updateClassesForSampleProducts();
      var productJSON = [{ quantity: 1, id:$(this).prop('id') }];
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/cart/add.js', true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({
        items: productJSON}))
      xhr.onload = function() {
        $(".cart__items").load(" .cart__items > *");
        $(".cart_total_price").load(" .cart_total_price > *");
      };	
    } else {
      $(this).find('input').removeAttr('checked');
      updateClassesForSampleProducts();
      var productJSON = { };
      productJSON['' + $(this).prop('id')] = 0;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/cart/update.js', true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({
        updates: productJSON}))
      xhr.onload = function() {
        $(".cart__items").load(" .cart__items > *");
        $(".cart_total_price").load(" .cart_total_price > *");
      };	
    }
  } else {
    if($(this).find('input').is(':disabled')) {
    } else if($(this).find('input').attr('checked')){
      $(this).find('input').prop('checked', false);
      $(this).find('input').removeAttr('checked');
      $(this).find('input').val(false);

      updateClassesForSampleProducts();
      var productJSON = { };
      productJSON['' + $(this).prop('id')] = 0;
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/cart/update.js', true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({
        updates: productJSON}))
      xhr.onload = function() {                   
        $(".cart__items").load(" .cart__items > *");
        $(".cart_total_price").load(" .cart_total_price > *");
      };	
    } else {
      $(this).find('input').prop('checked', true);
      $(this).find('input').attr('checked', 'checked');
      $(this).find('input').val(true);
      var productJSON = [{ quantity: 1, id: $(this).prop('id') }];
      updateClassesForSampleProducts();
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/cart/add.js', true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify({
        items: productJSON}))
      xhr.onload = function() {               
        $(".cart__items").load(" .cart__items > *");
        $(".cart_total_price").load(" .cart_total_price > *");
      };	
    }
  }  
});  
  
  var productJSON = {};
  var isSampleQtyGreater = false;
  var samples_count = 0;
  $('.sample-slider .grid-product').each(function() {
    if(cart_items.find(x => x.id == $(this).prop('id'))){
      samples_count = samples_count + 1;
      if(samples_count > max_sample) {
      	productJSON['' + $(this).prop('id')] = 0;
        isSampleQtyGreater = true;
        $(this).find('input').addClass("sample-checkbox-disabled");
        $(this).find('input').parents(".grid__item").find("#add-grid-item").prop("disabled", true);
        $(this).find('input').attr("disabled", true);
        $(this).find('input').prop('checked', false);
        $(this).find('input').removeAttr('checked');
        $(this).find('input').val(false);     
      } else {
        if(cart_items.find(x => x.id == $(this).prop('id')).quantity  > 1) {
          isSampleQtyGreater = true;
          productJSON['' + $(this).prop('id')] = 1;
        }
      }
      
    }
  });
  
  if(isSampleQtyGreater) {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", '/cart/update.js', true);
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.onreadystatechange = function () {
        if(xhr.readyState === XMLHttpRequest.DONE) {
          var status = xhr.status;
          if (status === 0 || (status >= 200 && status < 400)) {
            $(".cart__items").load(" .cart__items > *");
            $(".cart_total_price").load(" .cart_total_price > *");
          } else {
            // Oh no! There has been an error with the request!
          }
        }
      };
      xhr.send(JSON.stringify({
        updates: productJSON}))
      xhr.onload = function() {

      };	
    }
 
  $("#eye").click(function () {
    if ($(".show-password").attr("type") === "password") {
      $(".show-password").attr("type", "text");
    } else {
      $(".show-password").attr("type", "password");
    }
  });

   $('.ajaxcart__quantity').find('input').on('change', function() {
     $('body').trigger('added.ajaxProduct');
  })
   $('.ajaxcart__quantity').find('.js-qty__adjust.js-qty__adjust--minus').on('click', function(){
      $('body').trigger('added.ajaxProduct');
   })
   $('.ajaxcart__quantity').find('.js-qty__adjust.js-qty__adjust--plus').on('click',function(){
      $('body').trigger('added.ajaxProduct');
   })
   
   let first_trigger = false;
  let drawer = 0;

});

var $p =$('.m19-summary-links p');
    $p.first().html(function(){
        return $p.map(function(){
            return $(this).html() ;
        }).get().join(' ')
    }).end().filter(':gt(0)').remove();

function ScrollToDivId(id) {
    $("div").removeClass("flashit");
    $("#" + id).addClass("flashit");
    $('html,body').animate({
        scrollTop: $("#" + id).offset().top
    }, 'slow');
}

$('#m09-select').on('change', function (e) {
    var optionSelected = $("option:selected", this);
    var valueSelected = this.value;
    ScrollToDivId(valueSelected);
});


$(document).scroll(function() {
  var y = $(this).scrollTop();
  if (y > 600) {
    $('#sticky-product-parent').fadeIn();
  } else {
    $('#sticky-product-parent').fadeOut();
  }
});


/***************** m09 spy ****************/
$(document).ready(function() {
  var section = document.querySelectorAll(".m09-parent");
  var sections = {};
  var i = 0;

  var sectionTags = [];
  $(".m09-parent").find('a').each(function(e){
    var id = $(this).prop('href').split("#")[1];
    var tagId = "#"+id;
    sections[id] = $(tagId).parent().parent().offset().top;
  });

  window.onscroll = function() {
    var scrollPosition = document.documentElement.scrollTop || document.body.scrollTop;
    for (i in sections) {

      if (sections[i] <= scrollPosition) {
        $(".m09-parent").find('a[href*=' + i + ']').siblings().removeClass('active');
        $(".m09-parent").find('a[href*=' + i + ']').addClass('active');
        $(".m09-parent").find("#m09-select").val(i);
      }
    }
  };
})